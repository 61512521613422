import React from 'react';
import './App.css';

const AVIS = () => {
  return (
    <div className="concept-section">
≈      <div className="space-200px"></div>

<img src="ny.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700"  />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
Un vrai coup de cœur ! 
En juin 2023, j’ai fait confectionner sur-mesure mes 2 robes de mariée grâce aux précieux conseils de MANICA ! J’ai toujours eu très peur du sur-mesure, mais elle a su m’accompagner sur chaque étape et ensemble nous avons créé de vraies merveilles !
Mon entourage me complimentent encore sur mes robes, je vous la recommande vraiment !
Merci encore MANICA, c’était parfait !
</p>


<img src="yv.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700"  />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
Un grand MERCI à MANICA d’avoir réalisé ma magnifique robe de mariée sur mesure et personnalisée.<br></br>
Un accompagnement au top, de la confection de la robe, du choix du tissu jusqu’au jour du mariage, j’ai eu de la chance de l’avoir à mes côtés.<br></br>
Ma robe a fait son effet et j’en suis encore très reconnaissante.
Un énorme MERCI MANICA ! 
</p>


<img src="my.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700"  />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
J’ai fais appel à Manica pour ma robe de mariée. 
J’ai toujours voulu avoir une immense robe de princesse. Et elle l’a réalisée avec brio sur mesure, parfaite rien que pour moi.
Manica m’a accompagné pendant tout le long de la réalisation de ma robe de mariée et a su guider mes choix avec un professionnalisme extraordinaire. 
Je la recommande les yeux fermés. Le rapport qualité est incroyable ! Merci Manica !!
</p>



<img src="la.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700"  />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
MANICA c’est avant tout une très belle rencontre...
Passionnée par son métier et ultra douée pour donner vie à la robe que j’avais imaginé.<br></br>
Des échanges et conseils sur la création, en passant par la prise de mesures, le suivi de la fabrication à l’atelier, jusqu’à l’essayage, tout à été parfait !<br></br>
Osez franchir le pas de la création et personnalisation, votre robe sera unique comme cette journée que vous apprêtez à vivre !
Je la recommande les yeux fermés ! 
</p>



<img src="lo.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700"  />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
Pas de mots, simplement et clairement incroyable !!
Je n’ai pas hésité à faire confectionner ma robe de mariée sirène sur mesure par MANICA.<br></br>
Son service irréprochable, sa bienveillance très appréciée et ses rassurants conseils m’ont permis de voir venir le jour J arriver avec plus de confiance !<br></br>
Merci pour tout, je recommande ses services.<br></br>
Je suis venue de LAUSANNE sans regret !
</p>




<img src="ju.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700"  />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
Je recommande MANICA à 1000% la meilleure créatrice de robes de mariée.<br></br>
Les matières et les détails de ma robe sont exceptionnels !<br></br>
Merci pour tout ! 
</p>




<img src="el.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700" />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
J’ai  fais appel aux services de MANICA pour ma robe de mariée sur mesure.<br></br>
J’ai été très satisfaite. <br></br>
J’ai eu la robe de princesse que je voulais tant.<br></br>
MANICA est très professionnelle et à l’écoute, elle saura satisfaire vos exigences.<br></br>
Je la recommande ! 
</p>



<img src="celia.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700" />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
Manica a confectionné ma robe sur mesure à partir d’une seule photo « modèle ».
Le résultat était bien au delà des mes attentes et tellement mieux que sur ma photo d’inspiration.
Son professionnalisme et son détail de la perfection ont fait de moi une mariée plus que comblée.
Chaque petits détails de la robe auxquels je n’avais pas pensés ont été réfléchis, arrangés et embellis grâce à ses merveilleux conseils.
La robe qu’elle a entièrement conçue est unique comme le reste de ses créations. La qualité des tissus est indescriptible et elle mérite amplement d’être connue.
C’était la seule couturière qui était capable de réaliser ce défi en moins de deux mois.
Défi qu’elle a remporté à la perfection.
Merci encore ❤️
</p>



<img src="sophia.svg" alt="" className="small-centered-image" data-aos="zoom-in" data-aos-duration="700" />
<p className="description" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="400">
Manica a conçu ma robe de mariée à la perfection, elle a su répondre à mes attentes. Ma robe de mariée était magnifique. Je n'ai eu que des compliments : ).
Les tissus sont de grande qualité et le travail des détails très très bien réalisé.
Merci infiniment à elle pour son professionnalisme, son honnêteté ainsi que ces précieux conseils.
Je la recommande +++ sans hésitation
</p>
    </div>
  );
}

export default AVIS;